/*X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap
*/
.cardStyle {
    width: 11rem !important;
    padding: 0px !important;
    margin: 5px !important;
}

.carousel-indicators {
    display: none !important;
}

.carouselImageStyle {
    width: 25%;
}

.today-rtp-info {
    line-height: 18.5px;
    text-align: center;
    margin: 0;
}


.custom-padding {
    padding: 10px 0px 10px 0px !important;
}

.text-end {
    text-align: center !important;
}

body {
    color: white
}

.logo-size {
    width: 200px;
    height: 90px;
}

.hide-sort-label {
    display: none !important;
}

.title-style {
    line-height: 30px !important
}



/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

    body {
        color: white
    }

    .cardStyle {
        width: 18rem !important;
        padding: 0px !important;
        margin: 5px !important;
    }

    .carousel-indicators {
        display: none !important;
    }

    .carouselImageStyle {
        width: 10%;
        cursor: pointer;
    }

    .today-rtp-info {
        line-height: 20px;
    }

    .text-end {
        text-align: right !important;
    }

    .today-rtp-info {
        line-height: 20px;
        text-align: left !important;
    }

    .custom-padding {
        padding: 24px 60px 24px 60px !important;
    }

    .logo-size {
        width: 300px;
        height: 150px;
    }

    .hide-sort-label {
        display: inline !important;
    }

    .marginAllSidesStyle {
        margin: 0px 20px 0px 20px;
    }

    .title-style {
        line-height: 40px !important
    }
}

/*Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}*/

/*Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }*/

/*X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }*/

/*XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }*/